$text-color: #ffffff;
$input-bg: #F1F1F1;
$input-color: #4F4F4F;
$subscribe-btn-bg: #FF8B4A;
$subscribe-btn-shadow-color: rgba(255, 139, 74, 0.1);
$disabled-btn-bg: #ffa26e;

$phone-images: (
  'phone': url("../assets/IPhone.png"),
  'phone1': url("../assets/IPhone1.png"),
  'phone2': url("../assets/IPhone2.png"),
  'phone3': url("../assets/IPhone3.png"),
);

$phone-images-xs: (
  'phone': url("../assets/IPhone_xs.png"),
  'phone1': url("../assets/IPhone1_xs.png"),
  'phone2': url("../assets/IPhone2_xs.png"),
  'phone3': url("../assets/IPhone3_xs.png"),
);

@mixin render-bg-img($arr) {
  @each $class, $img in $arr {
    &.#{$class} {
      background-image: $img;
    }
  }
}
