@import './variables';
@import './slider';
@import './animations';

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 64px 64px 0 79px;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-rows: min-content 25% max-content max-content;
  grid-template-areas: "logo  ." ". ." "title phone" "form phone" ". phone";
  pointer-events: none;
  .logo {
    grid-area: logo;
  }

  .subscribe-form {
    grid-area: form;
    display: grid;
    grid-template-columns: minmax(auto, 512px) max-content;
    grid-template-areas: "title title" "input button" "checkbox checkbox";

    .form-title {
      font-family: Roboto, Bebas Neue, monospace;
      letter-spacing: -0.4px;
      margin: 0 0 24px 0;
      color: $text-color;
      font-weight: 400;
      grid-area: title;

      h2 {
        font-size: 24px;
        line-height: 22px;
        margin: 0 0 12px 0;
        font-weight: 400;
      }

      h3 {
        font-size: 17px;
        line-height: 22px;
        margin: 0;
        font-weight: 400;
      }
    }

    .form-sub-title {
      grid-area: sub-title;
    }

    .input-wrap {
      position: relative;
      grid-area: input;
      color: $input-color;
      width: 100%;
      max-width: 512px;

      .form-input {
        pointer-events: auto;
        width: 100%;
        height: 64px;
        background: $input-bg;
        border-radius: 8px;
        font-size: 20px;
        line-height: 23px;
        font-family: Roboto, Bebas Neue, monospace;
        border: none;
        padding: 20px 24px 21px;

        &[value=''] + .placeholder {
          display: flex;
        }
      }

      .placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 24px;
        height: 100%;
        display: none;
        align-items: center;
      }
    }

    .subscribe-btn {
      pointer-events: auto;
      width: 250px;
      height: 64px;
      border-radius: 8px;
      box-shadow: 0 10px 10px $subscribe-btn-shadow-color;
      background-color: $subscribe-btn-bg;
      border: none;
      font-family: Roboto, Bebas Neue, monospace;
      font-weight: bold;
      color: $text-color;
      font-size: 20px;
      line-height: 23px;
      grid-area: button;
      margin: 0 0 0 20px;
      cursor: pointer;
      &:disabled {
        background-color: $disabled-btn-bg;
        cursor: not-allowed;
      }
    }

    .beta-checkbox {
      pointer-events: auto;
      margin: 0 10px 0 0;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background-color: $text-color;
    }

    .beta-label {
      pointer-events: auto;
      font-size: 17px;
      color: $text-color;
      line-height: 22px;
      display: flex;
      align-items: center;
      grid-area: checkbox;
      margin: 8px 0 0 0;
    }
  }

  .title-wrap {
    grid-area: title;
    position: relative;

    .title {
      margin: 0 0 16px 0;
      font-size: 90px;
      line-height: 80px;
      font-family: Bebas Neue, Roboto, monospace;
      color: $text-color;
      text-align: left;
      grid-area: title;
      white-space: pre-wrap;
      letter-spacing: -0.4px;
      flex: 1 0 100%;
    }
  }

  .phone-img-wrapp {
    grid-area: phone;
    position: relative;
    align-self: end;
    justify-self: end;

    .phone-img {
      @include render-bg-img($phone-images);

      z-index: 1;
      width: 394px;
      height: 686px;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 0 0 15px;
    }
  }

  .phone-img-wrapp .phone-img, .title-wrap .title  {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;
  }

  .animate {
    &.title, &.phone-img {
      position: relative;
      opacity: 1;
      visibility: visible;
      transition: opacity 1s .5s;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .content {
    .title {
      font-size: 60px;
      line-height: 63px;
    }
    .subscribe-form {
      grid-template-areas: "title title" "input input" "button button" "checkbox checkbox";
      .form-input, .subscribe-btn {
        width: 100%;
      }
      .subscribe-btn {
        max-width: 512px;
        margin: 16px 0 8px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  .container {
    height: auto;
  }

  .content {
    padding: 16px 20px 47px;
    grid-template-columns: auto;
    grid-template-rows: auto 48px minmax(165px, max-content) auto;
    grid-template-areas: "logo" "." "title" "phone" "form";


    .title-wrap {
      .title {
        font-size: 48px;
        line-height: 42px;
      }
    }

    .subscribe-form {
      margin: 33px 0 0;
      justify-items: center;
      grid-template-columns: auto;
      grid-template-areas: "title" "input" "checkbox" "button";
      justify-self: center;
      .form-title {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        margin: 0 0 14px 0;
      }
      .form-input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        padding: 12px 8px;
        margin: 0;
      }

      .subscribe-btn {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
      }

      .beta-checkbox {
        pointer-events: auto;
        margin: 0 10px 0 0;
      }

      .beta-label {
        font-size: 14px;
        line-height: 12px;
        margin: 18px 0 24px;
      }
    }

    .phone-img-wrapp {
      width: 248px;
      height: 526px;
      justify-self: center;
      .phone-img {
        @include render-bg-img($phone-images-xs);

        margin: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 320px) {
  .content {
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas: "logo" "title" "form";
    .title {
      align-self: center;
    }

    .phone-img-wrapp {
      display: none;
    }

    .subscribe-form {
      grid-template-areas: "title title" "input input" "button button" "checkbox checkbox";
      .form-title {
        text-align: left;
      }

      .input-wrap {
        margin: 0 0 14px;

        .placeholder {
          font-size: 12px;
        }
      }

      .subscribe-btn {
        margin: 0;
      }

      .beta-label {
        margin: 18px 0 0;
      }
    }
  }
}
