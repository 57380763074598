.image-gallery-slide {
  opacity: 0;
  transform: none!important;
  transition: opacity 1s !important;
}

.image-gallery {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  .image-gallery-content {
    height: 100%;
    .image-gallery-slide-wrapper {
      height: 100%;
      .image-gallery-swipe {
        height: 100%;
        .image-gallery-slides {
          height: 100%;
          .image-gallery-slide {
            height: 100%;
            &.center {
              transform: translate3d(0px, 0px, 0px);
              transition: opacity 1s .5s!important;
              opacity: 1;
            }
            div {
              height: 100%;
              .image-gallery-image {
                height: 100%;
                object-fit: cover;
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .image-gallery-bullets {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    margin: 0 0 0 28px;

    .image-gallery-bullets-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-gallery-bullet {
        width: 8px;
        height: 8px;
        background: $text-color;
        margin: 12px 0;
        border-radius: 50%;
        position: relative;
        transition: all ease-out .3s;
        display: block;
        padding: 0;
        box-shadow: none;

        &.active {
          width: 24px;
          height: 24px;
          background: transparent;
          border: 1px solid $text-color;
          margin: 4px 0;
          &:after {
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffffff;
            position: absolute;
            top: 50%;
            margin-top: -2px;
            left: 50%;
            margin-left: -2px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .image-gallery {
    .image-gallery-bullets {
      justify-content: flex-start;
      right: 0;
      left: initial;
      margin: 0 8px 0 0;
      padding: 80px 0 0;
    }
  }
}

@media (max-width: 320px) {
  .image-gallery {
    .image-gallery-bullets {
      width: 100%;
      height: 24px;
      bottom: 16px;
      padding: 0;
      justify-content: center;

      .image-gallery-bullets-container {
        flex-direction: row;
        justify-content: center;
        .image-gallery-bullet {
          margin: 0 12px;

          &.active {
            margin: 0 4px;
          }
        }
      }
    }
  }
}
